import PropTypes from "prop-types"
import React from "react"
import ModalTriggerButton from "../../../UI/ModalTriggerButton"
import { Button } from "react-bootstrap"
import { navigate } from "gatsby"

const ActionButton = ({ config, buttons }) => {

  const { padding } = config

  let paddingClasses = Object.values(padding).reduce(
    (prev, curr) => (prev += Object.values(curr).join(" ")),
    ""
  )

  return (
    <div className={`${paddingClasses} d-flex justify-content-center`}>
      {buttons.map(button => {
        const { buttonText, action, product } = button
        switch (action) {
          case "partExchange":
          case "testRide":
            return (
              <ModalTriggerButton
                btnClassName="px-4"
                variant="outline-primary"
                className="mr-lg-1"
                buttonText={buttonText}
                type={action}
                heading={
                  action === "partExchange"
                    ? "Exchange your parts with us"
                    : "Book a free test ride"
                }
                product={product}
                location={window.location.pathname}
              />
            )
          case "reserve":
            return (
              <Button
                data-track-event="reserve"
                variant="outline-primary"
                className="px-4"
                onClick={() =>
                  navigate("/bike-reservation", {
                    state: product,
                  })
                }>
                {buttonText}
              </Button>
            )
          case "link":
            return (
              <Button
                onClick={() => navigate(`/products/${product.databaseId}`)}>
                {buttonText}
              </Button>
            )
        }
      })}
    </div>
  )
}

ActionButton.propTypes = {
  buttons: PropTypes.shape({
    map: PropTypes.func,
  }),
  config: PropTypes.shape({
    padding: PropTypes.any,
  }),
}
export default ActionButton
